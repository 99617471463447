import React, { Component } from 'react'
import Link from '../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class OurRange extends Component {

  render() {

    let { title, items } = this.props

    return (

      <section className='our-range'>

        <div className='our-range__inner'>

          <h2>{title}</h2>
            
          <div className='our-range__items'>

            {items.map((el, i) => (
              <div className='our-range__item' key={i}>
                <GatsbyImage loading='lazy' image={getImage(el.image?.localFile)} alt={el.title} />
                <h4>{el.title}</h4>
                <Link to={el.link}></Link>
              </div>
            ))}

          </div>

        </div>

      </section>
    )
  }
}

export default OurRange