import React, { Component } from 'react'

class BasicPage extends Component {

  render() {

    let { title, content } = this.props

    return (
      <section className='basic'>
        <div className='basic__inner'>
          <div className='basic__content'>
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </section>
    )
  }
}

export default BasicPage