import React, { Component } from 'react'
import ComparisonSlide from './comparison-slide';
import Slider from "react-slick";

class ComparisonSlider extends Component {

  render() {

    let { title, content, items } = this.props

    const settings = {
      infinite: true,
      speed: 600,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      cssEase: 'ease',
      pauseOnHover: true,
      swipe: false,
      focusOnSelect: false,
      adaptiveHeight: true,
      pauseOnHover:true
    }

    return (

      <section className='comparison-slider'>

        <div className='comparison-slider__inner'>

          { title && <h2 className='comparison-slider__heading' dangerouslySetInnerHTML={{ __html: title }} /> }

          { content && <p className='comparison-slider__content' dangerouslySetInnerHTML={{ __html: content }} /> }
          
          { items && 

          <div className='comparison-slider__items'>

            <Slider {...settings}>

              {items.map((el, i) => (

                  <div key={i} className='comparison-slider__slide' key={i}>
                    <ComparisonSlide beforeImage={el.beforeImage} afterImage={el.afterImage} sliderNumber={i} ></ComparisonSlide>
                  </div>

              ))}

            </Slider>

          </div>

          }

        </div>

      </section>
    )
  }
}

export default ComparisonSlider