import React, { Component } from 'react'
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
  ReactCompareSliderImage
} from "react-compare-slider"

class ComparisonSlide extends Component {

  render() {

    let { beforeImage, afterImage, sliderNumber } = this.props

    return (

        <div className='comparison-slide' id={sliderNumber} style={{ width: 900, height: 450 }}>

          <ReactCompareSlider
            handle={
              <ReactCompareSliderHandle
                buttonStyle={{
                  backdropFilter: undefined,
                  background: "white",
                  border: 0,
                  color: "#E83B68"
                }}
              />
            }
            itemOne={
              <ReactCompareSliderImage
                src={beforeImage?.localFile.childImageSharp.original.src}
                alt="one"
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src={afterImage?.localFile.childImageSharp.original.src}
                alt="two"
              />
            }
          />

        </div>
    )
  }
}

export default ComparisonSlide