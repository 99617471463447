import React, { Component } from 'react'
import Link from '../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class WhoWeWorkWith extends Component {

  render() {

    let { title, content, items } = this.props

    return (

      <section className='our-range'>

        <div className='our-range__inner'>

          <h2>{title}</h2>

          <p className='our-range__content' dangerouslySetInnerHTML={{ __html: content }} />
            
          <div className='our-range__items'>

            {items.map((el, i) => (
              <div className='our-range__item' key={i}>
                <GatsbyImage loading='lazy' image={getImage(el.image?.localFile)} alt={el.title} />
                { el.logo && <GatsbyImage loading='lazy' image={getImage(el.logo?.localFile)} alt={el.title} className='logo' /> }
                { el.title && 
                  <h4>
                    {el.title}
                    { el.socialHandle && <span>{el.socialHandle}</span> }
                  </h4>
                }
                { el.link && <Link to={el.link}></Link> }
              </div>
            ))}

          </div>

        </div>

      </section>
    )
  }
}

export default WhoWeWorkWith