import React, { Component } from 'react'
import Link from '../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class Instagram extends Component {

  render() {

    let { overlay, leftTopImage, leftBottomImage, rightTopImage, rightBottomImage } = this.props

    return (

      <section className='instagram'>
        <div className='instagram__inner'>
          <div className='instagram__images'>

            <div className='instagram__overlay'>
              { overlay.title && 
                <h2 className='instagram__heading'>{ overlay.title }</h2>
              }
              { overlay.handle && 
                <div className='instagram__handle' dangerouslySetInnerHTML={{ __html: overlay.handle }} />
              }
            </div>

            <div className='instagram__left'>
              <GatsbyImage className='instagram__left-top' loading='lazy' image={getImage(leftTopImage?.localFile)} alt="" />
              <GatsbyImage className='instagram__left-bottom' loading='lazy' image={getImage(leftBottomImage?.localFile)} alt="" />
            </div>
            <div className='instagram__right'>
              <GatsbyImage className='instagram__right-top' loading='lazy' image={getImage(rightTopImage?.localFile)} alt="" />
              <GatsbyImage className='instagram__right-bottom' loading='lazy' image={getImage(rightBottomImage?.localFile)} alt="" />
            </div>
            
          </div>
        </div>
      </section>
    )
  }
}

export default Instagram