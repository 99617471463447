import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class PostImage extends Component {

  render() {

    let { image, caption } = this.props

    return (
      <section className='basic basic--image'>
        <div className='basic__inner'>
          <div className='basic__content'>
            <GatsbyImage loading='lazy' image={getImage(image?.localFile)} alt={caption} />
            <span className='basic__caption'>{caption}</span>
          </div>
        </div>
      </section>
    )
  }
}

export default PostImage