import React, { Component } from 'react'
import Link from '../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class Gallery extends Component {

  render() {

    let { title, items } = this.props

    return (

      <section className='gallery'>

        <div className='gallery__inner'>

          <div className='gallery__title'>
            <h2>{ title }</h2>
          </div>
            
          <div className='gallery__items'>

            {items.map((el, i) => (

              <div className='gallery__item' key={i}>

                <div className='gallery__item-left-panel'>
                  <GatsbyImage className='gallery__top-left-image secondary' loading='lazy' image={getImage(el.panel.topLeftImage?.localFile)} alt={title} />
                  <GatsbyImage className='gallery__bottom-left-image hero' loading='lazy' image={getImage(el.panel.bottomLeftImage?.localFile)} alt={title} />
                </div>

                <div className='gallery__item-middle-panel'>
                  <GatsbyImage className='gallery__top-centre-image hero' loading='lazy' image={getImage(el.panel.topCentreImage?.localFile)} alt={title} />
                  <GatsbyImage className='gallery__bottom-centre-image secondary' loading='lazy' image={getImage(el.panel.bottomCentreImage?.localFile)} alt={title} />
                </div>

                <div className='gallery__item-right-panel'>
                  <GatsbyImage className='gallery__top-right-image secondary' loading='lazy' image={getImage(el.panel.topRightImage?.localFile)} alt={title} />
                  <GatsbyImage className='gallery__bottom-right-image hero' loading='lazy' image={getImage(el.panel.bottomRightImage?.localFile)} alt={title} />
                </div>
               
              </div>

            ))}

          </div>

        </div>

      </section>
    )
  }
}

export default Gallery