import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../utils/link'

class RangeHeader extends Component {

  render() {

    let { image, icon, title, content, text, link, images } = this.props

    return (
      <section className='range-header'>

        <div className='range-header__inner'>

            <div className='range-header__item'>

              <div className='range-header__image'>

                <GatsbyImage loading='lazy' image={getImage(image?.localFile)} alt={title} />

                { icon && 
                  <div className='range-header__icon'>
                    <GatsbyImage loading='lazy' image={getImage(icon?.localFile)} alt={title} />
                  </div>
                }

              </div>

              <div className='range-header__content'>

                <hr className='range-header__keyline' />

                <div className='range-header__title'>
                  <h1>{ title }</h1>
                </div>

                { content && 
                  <div className='range-header__content-content' dangerouslySetInnerHTML={{ __html: content }} />
                }

                { link && 
                  <div className='range-header__btn'>
                    <Link className='btn btn--primary' to={link}>{text}</Link>
                  </div>
                }

                {images && 
                  ( 
                    <div className='range-header__images'>
                      {images.map((el, i) => (
                        <div className='range-header__images-image'>
                          <GatsbyImage loading='lazy' image={getImage(el.image?.localFile)} alt={title} />
                        </div>
                      ))}
                    </div>
                  )
                }

              </div>

            </div>

        </div>

      </section>
    )
  }
}

export default RangeHeader