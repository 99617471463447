import React, { Component } from 'react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Link from '../../utils/link'

import TickBlack from '../../assets/images/tick-black.svg'

//import Fade from 'react-reveal/Fade'

class CircleCard extends Component {

  render() {

    let { image, title, content, text, link, layout } = this.props

    return (

      <section className={`circle-card circle-card-${layout}`}>

        <div className='circle-card__image'>
          <GatsbyImage loading='lazy' image={getImage(image?.localFile)} alt={title} />
        </div>

        <div className='circle-card__container'>

          <div className='circle-card__title'>
              <img src={TickBlack} alt='Tick' className='circle-card__tick' />
              <h3 className='circle-card__heading' dangerouslySetInnerHTML={{ __html: title }} />
          </div>

          { content && 
            <div className='circle-card__content' dangerouslySetInnerHTML={{ __html: content }} /> 
          }

          { link && 
            <Link to={link}>{ text }</Link>
          }

        </div>

      </section>
    )
  }
}

export default CircleCard
