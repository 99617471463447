import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../utils/link'

class HeroBanner extends Component {

  render() {

    let { leftImage, rightImage, overlay, title, content, button } = this.props

    return (
      <section className='hero-banner'>
        <div className='hero-banner__inner'>
          <div className='hero-banner__images'>
            <GatsbyImage className='hero-banner__left' loading='lazy' image={getImage(leftImage?.localFile)} alt={title} />
            <GatsbyImage className='hero-banner__right' loading='lazy' image={getImage(rightImage?.localFile)} alt={title} />
            { overlay.title &&
              <div className='hero-banner__overlay'>
                { overlay.title && 
                  <h1>{ overlay.title }</h1>
                }
                <ul>
                  { overlay.button.link && 
                    <li><Link className='btn btn--primary' to={overlay.button.link}>{overlay.button.text}</Link></li>
                  }
                  {/*{ overlay.secondButton.link && */}
                  {/*  <li><Link className='btn btn--secondary' to={overlay.secondButton.link}>{overlay.secondButton.text}</Link></li>*/}
                  {/*}*/}
                </ul>
              </div>
            }
          </div>
          <div className='hero-banner__content'>
            { title && 
              <h2>{ title }</h2>
            }
            { content && 
              <div dangerouslySetInnerHTML={{ __html: content }} />
            }
            { button.link && 
              <Link className='btn btn--secondary' to={button.link}>{button.text}</Link>
            }
          </div>
        </div>
      </section>
    )
  }
}

export default HeroBanner
