import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import DesignConsultationForm from './design-consultation-form'
import Link from '../utils/link'

class DesignConsultationHeader extends Component {

  render() {

    let { title, content, topics, button } = this.props

    return (

      <section className='design-consultation-header'>
        <div className='design-consultation-header__inner'>
          <div className='design-consultation-header__items'>
            <div className='design-consultation-header__item'>
              <div className='design-consultation-header__content'>

                <h1 className='design-consultation-header__heading'>{ title }</h1>

                { content && 
                  <div className='design-consultation-header__content-content' dangerouslySetInnerHTML={{ __html: content }} />
                }

                { topics && 
                  <div className='design-consultation-header__content-topics' dangerouslySetInnerHTML={{ __html: topics }} />
                }

                { button.link && 
                  <Link className='design-consultation-header__content-button btn btn--secondary' to={button.link}>{button.text}</Link>
                }
                
              </div>
              <div className='design-consultation-header__form'>
                <DesignConsultationForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DesignConsultationHeader