import React, { Component } from 'react'
//import Fade from 'react-reveal/Fade'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

class FrequentlyAskedQuestions extends Component {

  state = {
    accordionOpen: false,
  }

  _toggleAccordion = (accordionItem) => {
    this.setState({accordionOpen: {[accordionItem]: !this.state.accordionOpen[accordionItem]} })
  }

  render() {

    let { title, questions } = this.props
    
    return (

      <section className='frequently-asked-questions'>

        <div className='frequently-asked-questions__inner'>
            
          <div className='frequently-asked-questions__title'>
            <h2>{ title }</h2>
          </div>

          <div className='frequently-asked-questions__accordion'>

            { questions && <div className='accordion'>

                { questions.map((el, i) => (
                 <div className={this.state.accordionOpen[i] ? 'accordion__item active' : 'accordion__item'} key={i}>
                  <h3 className='accordion__title' onClick={() => this._toggleAccordion(i)}>
                    <span className='title'>{el.title}</span>
                    <FontAwesomeIcon icon={this.state.accordionOpen[i] ? faAngleUp : faAngleDown } />
                  </h3>
                  <div className='accordion__content' dangerouslySetInnerHTML={{ __html: el.content }} />
                 </div>
                ))}

              </div>
            }

          </div>

        </div>

      </section>
    )
  }
}

export default FrequentlyAskedQuestions
