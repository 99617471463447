import React, { Component } from 'react'
import Link from '../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
//import Fade from 'react-reveal/Fade'

class HowItWorksBlocks extends Component {

  render() {

    let { items } = this.props

    return (

      <section className='how-it-works-blocks'>

        <div className='how-it-works-blocks__inner'>

          <div className='how-it-works-blocks__items'>

            {items.map((el, i) => {
              
              return (

                <div key={i} className={`how-it-works-blocks__item ${el.reverse ? 'reverse' : ''}`}>

                  <div className='how-it-works-blocks__item-image'>

                    <GatsbyImage loading='lazy' image={getImage(el.image?.localFile)} alt={el.title} />

                  </div>

                  <div className='how-it-works-blocks__item-container'>

                    <div className='how-it-works-blocks__item-icon'>

                        <div className='how-it-works-blocks__item-circle'>

                          <GatsbyImage loading='lazy' image={getImage(el.icon?.localFile)} alt={el.title} />

                        </div>

                    </div>
                  
                    <div className='how-it-works-blocks__item-title'>

                      <div className='how-it-works-blocks__item-symbol'>
                        { i + 1 }
                      </div>

                      <h3 className='how-it-works-blocks__item-heading'>{ el.title }</h3>

                    </div>

                    <div className='how-it-works-blocks__item-content'>
                      { el.content }
                    </div>

                  </div>

                </div>

              );

            })}

          </div>
        </div>
      </section>
    )
  }
}

export default HowItWorksBlocks
