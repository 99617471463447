
import React, { Component } from 'react'
import Link from '../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

class DiyBenefits extends Component {

  render() {

    let { title, items } = this.props

    return (

      <section className='diy-benefits'>

        <div className='diy-benefits__inner'>
            
          <div className='diy-benefits__items'>

            {items.map((el, i) => (

              <div className={`diy-benefits__item ${el.reverse ? 'reverse' : ''}`} key={i}>

                <div className='diy-benefits__item-image'>
                  <GatsbyImage loading='lazy' image={getImage(el.image?.localFile)} alt={el.title} />
                </div>

                <div className='diy-benefits__item-content' >

                  <div className='diy-benefits__item-title'>
                    <h2>{ el.title }</h2>
                  </div>

                  { el.content && <div dangerouslySetInnerHTML={{ __html: el.content }} /> }

                </div>

              </div>

            ))}

          </div>

        </div>

      </section>
    )
  }
}

export default DiyBenefits