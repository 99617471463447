import React, { Component } from 'react'

class TermsAndConditionsBlocks extends Component {

  render() {

    let { title, items } = this.props

    return (
      <section className='basic'>
        <div className='basic__inner'>
          <div className='basic__content'>
            <h1 className="basic__heading" dangerouslySetInnerHTML={{ __html: title }} />
            { items && items.map((el, i) => (
              <div className='basic__block-content'>
                <h3 dangerouslySetInnerHTML={{ __html:  ( ( i + 1 ) + ". " + el.title ) }} />
                <div dangerouslySetInnerHTML={{ __html: el.content }} />
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

export default TermsAndConditionsBlocks