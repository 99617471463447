import React, { Component } from 'react'
import CircleCard from './cards/circle-card'
//import Fade from 'react-reveal/Fade'

class CircleImageItems extends Component {

  render() {

    let { title, content, layout, items } = this.props

    return (
      <section className={`circle-image-items ${layout}`}>
        <div className='circle-image-items__inner'>
          { title && <h2 className={`circle-image-items__heading ${ content ? '' : 'no-content' }`} dangerouslySetInnerHTML={{ __html: title }} /> }
          { content && <div className='circle-image-items__content' dangerouslySetInnerHTML={{ __html: content }} /> }
          <div className='circle-image-items__items'>
            { items.map((el, i) => (
              <CircleCard {...el} order={i} layout={layout} key={i}></CircleCard>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

export default CircleImageItems
