import React, { Component } from 'react'

class PostText extends Component {

  render() {

    let { text } = this.props

    return (
      <section className='basic basic--text'>
        <div className='basic__inner'>
          <div className='basic__content'>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </div>
      </section>
    )
  }
}

export default PostText