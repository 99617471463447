import React from 'react'

import HeroBanner from '../components/hero-banner'
import CircleImageItems from '../components/circle-image-items'
import OurRange from '../components/our-range'
import HowItWorks from '../components/how-it-works'
import HowItWorksBlocks from '../components/how-it-works-blocks'
import ReadyToGetStarted from '../components/ready-to-get-started'
import WhoWeWorkWith from '../components/who-we-work-with'
import DesignConsultationHeader from '../components/design-consultation-header'
import Instagram from '../components/instagram'
import FrequentlyAskedQuestions from '../components/frequently-asked-questions'
import ComparisonSlider from '../components/comparison-slider'
import DiyBenefits from '../components/diy-benefits'
import BasicPage from '../components/basic-page'
import PageTitle from '../components/page-title'
import TermsAndConditionsBlocks from '../components/terms-and-conditions-blocks'
import Gallery from '../components/gallery'
import RangeHeader from '../components/range-header'
import PostText from '../components/post-text'
import PostImage from '../components/post-image'

const RenderBlock = (param, el, i, postType) => {
  param = param?.replace(postType, '')
  let block = {
  	'_Acf_Components_HeroBanner' : (el, i) => <HeroBanner key={i} {...el} />,
    '_Acf_Components_PageTitle' : (el, i) => <PageTitle key={i} {...el} />,
    '_Acf_Components_CircleImageItems' : (el, i) => <CircleImageItems key={i} {...el} />,
    '_Acf_Components_OurRange' : (el, i) => <OurRange key={i} {...el} />,
    '_Acf_Components_HowItWorks' : (el, i) => <HowItWorks key={i} {...el} />,
    '_Acf_Components_HowItWorksBlocks' : (el, i) => <HowItWorksBlocks key={i} {...el} />,
    '_Acf_Components_ReadyToGetStarted' : (el, i) => <ReadyToGetStarted key={i} {...el} />,
    '_Acf_Components_WhoWeWorkWith' : (el, i) => <WhoWeWorkWith key={i} {...el} />,
    '_Acf_Components_DesignConsultationHeader' : (el, i) => <DesignConsultationHeader key={i} {...el} />,
    '_Acf_Components_Instagram' : (el, i) => <Instagram key={i} {...el} />,
    '_Acf_Components_FrequentlyAskedQuestions' : (el, i) => <FrequentlyAskedQuestions key={i} {...el} />,
    '_Acf_Components_ComparisonSlider' : (el, i) => <ComparisonSlider key={i} {...el} />,
    '_Acf_Components_DiyBenefits' : (el, i) => <DiyBenefits key={i} {...el} />,
    '_Acf_Components_BasicPage' : (el, i) => <BasicPage key={i} {...el} />,
    '_Acf_Components_TermsAndConditionsBlocks' : (el, i) => <TermsAndConditionsBlocks key={i} {...el} />,
    '_Acf_Components_Gallery' : (el, i) => <Gallery key={i} {...el} />,
    '_Acf_Components_RangeHeader' : (el, i) => <RangeHeader key={i} {...el} />,
    '_Acf_Content_Text' : (el, i) => <PostText key={i} {...el} />,
    '_Acf_Content_Image' : (el, i) => <PostImage key={i} {...el} />,
  }[param]

  if (!block) return null

  return block(el, i)
}

export default RenderBlock