import React, { Component } from 'react'
import Link from '../utils/link'
import moment from 'moment'
import tag from "../utils/tag"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends Component {
  state = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    postcode: '',
    project_type: '',
    interested_in: '',
    diy_updates: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    this.setState({ result: 'success' })
    const data = encode({
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      phone: this.state.phone,
      postcode: this.state.postcode,
      project_type: this.state.project_type,
      interested_in: this.state.interested_in,
      diy_updates: this.state.diy_updates,
    })
    await fetch("https://hooks.zapier.com/hooks/catch/2181815/b0kdyu4/?"+data)
    tag("Form Submission", "Design Consult")

    if (this.state.diy_updates) {
      tag("Form Submission", "Join Email List")
    }

  }

  render() {
    let props = {
      ref: "form",
      name: "consultation",
      className: "form",
      onSubmit: this.handleSubmit,
    }

    let { date } = this.state

    if (this.state.result === "success")
      return (
        <p className="contact__success">
          Thanks for getting in contact with us.
          <br /> A member of our team will be in touch soon.
        </p>
      )

    return (
      <form {...props}>
        <div className="form__row">

          <label className="form__row--label" htmlFor="first-name-field">First name <span className="form__row--required-token">*</span>

            <input
              type="text"
              name="first_name"
              id="first-name-field"
              placeholder=""
              onChange={this.handleChange}
              required
            />

          </label>

        </div>
        <div className="form__row">

          <label className="form__row--label" htmlFor="last-name-field">Last name <span className="form__row--required-token">*</span>

            <input
              type="text"
              name="last_name"
              id="last-name-field"
              placeholder=""
              onChange={this.handleChange}
              required
            />

          </label>

        </div>
        <div className="form__row">

          <label className="form__row--label" htmlFor="email-field">Email <span className="form__row--required-token">*</span>

            <input
              type="email"
              name="email"
              id="email-field"
              placeholder=""
              onChange={this.handleChange}
              required
            />

          </label>

        </div>
        <div className="form__row">

          <label className="form__row--label" htmlFor="phone-field">Phone Number <span className="form__row--required-token">*</span>

            <input
              type="text"
              name="phone"
              id="phone-field"
              onChange={this.handleChange}
              required
            />

          </label>

        </div>
        <div className="form__row">

          <label className="form__row--label" htmlFor="postcode-field">Postcode <span className="form__row--required-token">*</span>

          <input
            type="text"
            name="postcode"
            id="postcode-field"
            onChange={this.handleChange}
            required
          />

          </label>

        </div>

        <div className="form__row form__row--radio">

          <label className="form__row--label" htmlFor="project-type">Project Type <span className="form__row--required-token">*</span></label>

          <div className='form__row--radio-row'>

            <div className="form__radio">

              <input
                type="radio"
                name="project_type"
                id="project-type-new-build"
                value="New Build"
                onChange={this.handleChange}
              />

              <label className="form__row--label" htmlFor="project-type-new-build">New Build</label>

            </div>

            <div className="form__radio">

              <input
                type="radio"
                name="project_type"
                id="project-type-renovation"
                value="Renovation / Extension"
                onChange={this.handleChange}
              />

              <label className="form__row--label" htmlFor="project-type-renovation">Renovation / Extension</label>

            </div>

          </div>

        </div>

        <div className="form__row form__row--radio form__row--radio-bulk">

          <label className="form__row--label" htmlFor="project-type">I'm interested in <span className="form__row--required-token">*</span></label>

          <div className='form__row--radio-row'>

            <div className="form__radio">

              <input
                type="radio"
                name="interested_in"
                id="interested-in-kitchen"
                value="Kitchen"
                onChange={this.handleChange}
              />

              <label className="form__row--label" htmlFor="interested-in-kitchen">Kitchen</label>

            </div>

            <div className="form__radio">

              <input
                type="radio"
                name="interested_in"
                id="interested-in-laundry"
                value="Laundry"
                onChange={this.handleChange}
              />

              <label className="form__row--label" htmlFor="interested-in-laundry">Laundry</label>

            </div>

            <div className="form__radio">

              <input
                type="radio"
                name="interested_in"
                id="interested-in-bathroom-vanities"
                value="Bathroom Vanities"
                onChange={this.handleChange}
              />

              <label className="form__row--label" htmlFor="interested-in-bathroom-vanities">Bathroom Vanities</label>

            </div>

            <div className="form__radio">

              <input
                type="radio"
                name="interested_in"
                id="interested-in-window-furnishings"
                value="Window Furnishings"
                onChange={this.handleChange}
              />

              <label className="form__row--label" htmlFor="interested-in-window-furnishings">Window Furnishings</label>

            </div>

            <div className="form__radio">

              <input
                type="radio"
                name="interested_in"
                id="interested-in-wardrobe"
                value="Wardrobe"
                onChange={this.handleChange}
              />

              <label className="form__row--label" htmlFor="interested-in-wardrobe">Wardrobe</label>

            </div>

            <div className="form__radio">

              <input
                type="radio"
                name="interested_in"
                id="interested-in-flooring"
                value="Flooring"
                onChange={this.handleChange}
              />

              <label className="form__row--label" htmlFor="interested-in-flooring">Flooring</label>

            </div>

          </div>

        </div>

        <div className="form__row form__row--radio">

          <label className="form__row--label" htmlFor="postcode-field">Send me Home Design Co updates<span className="form__row--required-token">*</span></label>

          <div className="form__row--radio-row">

            <div className='form__radio'>

              <input
                type="radio"
                name="diy_updates"
                id="diy-updates-yes"
                value="Yes"
                onChange={this.handleChange}
              />

              <label className="form__row--label" htmlFor="diy-updates-yes">Yes</label>

            </div>

            <div className="form__radio">

              <input
                type="radio"
                name="diy_updates"
                id="diy-updates-no"
                value="No"
                onChange={this.handleChange}
              />

              <label className="form__row--label" htmlFor="diy-updates-no">No</label>

            </div>

          </div>

        </div>

        <div className="form__row form__row--submit">
          <button type="submit" className="btn btn--primary">
            Book Now
          </button>
        </div>

        <div className="form__row">
          <p className="form__row_privacy">We respect your privacy.<br />
          Learn more about our <Link to="/privacy-policy/">privacy policy</Link>.</p>
        </div>

      </form>
    )
  }
}

export default Form
