import React, { Component } from 'react'
import Link from '../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
//import Fade from 'react-reveal/Fade'

class HowItWorks extends Component {

  render() {

    let { title, items, buttons } = this.props

    return (

      <section className='how-it-works'>

        <div className='how-it-works__inner'>
            
          <div className='how-it-works__heading'>
            { title }
          </div>

          <div className='how-it-works__items'>

            {items.map((el, i) => {
              
              return (

                <div key={i} className='how-it-works__item'>

                  <div className='how-it-works__item-image'>

                    <div className='how-it-works__circle'>

                      <GatsbyImage loading='lazy' image={getImage(el.image?.localFile)} alt={el.title} />

                    </div>

                  </div>

                  <div className='how-it-works__item-title'>

                    <div className='how-it-works__item-symbol'>
                      { i + 1 }
                    </div>

                    <h3 className='how-it-works__item-heading'>{ el.title }</h3>

                  </div>

                  <div className='how-it-works__item-content'>
                    { el.content }
                  </div>

                </div>

              );

            })}

          </div>

          <div className='how-it-works__buttons'>

            {buttons.map((el, i) => {

              return ( 

               <div key={i} className='how-it-works__button'>

                <Link className='btn btn--secondary' to={el.button.link}>{el.button.text}</Link>
               </div>

              );

            })}

          </div>
        </div>
      </section>
    )
  }
}

export default HowItWorks
