import React, { Component } from 'react'

class PageTitle extends Component {

  render() {

    let { title } = this.props

    return (
      <section className='page-title'>
        <div className='page-title__inner'>
          <h1>{ title }</h1>
        </div>
      </section>
    )
  }
}

export default PageTitle
